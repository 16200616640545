export const algorithms = [
  "Dijkstra Algorithm",
  // "A* Algorithm",
  // "Greedy Best-first Search",
  // "Swarm Algorithm",
  // "Convergent Swarm Algorithm",
  // "Bidirectional Swarm Algorithm",
  // "BFS",
  // "DFS",
];
