import React from "react";
import "./Nodes.css";

const Nodes = ({
  row,
  col,
  isFinish,
  isStart,
  isWall,
  onMouseDown,
  onMouseEnter,
  onMouseUp,
}) => {
  const extraClassName = isWall && "nodeWall";
  return (
    <div
      className={`node ${extraClassName}`}
      id={`node-${row}-${col}`}
      onMouseDown={() => onMouseDown(row, col)}
      onMouseEnter={() => onMouseEnter(row, col)}
      onMouseUp={() => onMouseUp()}
    >
      {isStart && (
        <img
          src="https://nanthakumaran-s.github.io/pathfinding-visualizer/startIndicator.svg"
          alt="start"
          className="indicator"
        />
      )}
      {isFinish && (
        <img
          src="https://nanthakumaran-s.github.io/pathfinding-visualizer/endIndicator.svg"
          alt="end"
          className="indicator"
        />
      )}
    </div>
  );
};

export default Nodes;
