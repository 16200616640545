import React, { useEffect, useState } from "react";
import "./PathFindingVisualizer.css";
import Nodes from "./Nodes/Nodes";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { dijkstra, getNodesInShortestPathOrder } from "../algorithms/dijkstra";
import { algorithms } from "../data";

var START_NODE_ROW = 5;
var START_NODE_COL = 5;
var FINISH_NODE_ROW = 10;
var FINISH_NODE_COL = 25;

const PathFindingVisualizer = () => {
  const [dropDown, setDropDown] = useState(false);
  const [algo, setAlgo] = useState("");
  const [grid, setGrid] = useState([]);
  const [mouseIsPressed, setMouseIsPressed] = useState(false);
  const [moveStartNode, setMoveStartNode] = useState(false);
  const [moveEndNode, setMoveEndNode] = useState(false);
  const [prevNode, setPrevNode] = useState({});
  const [inProgress, setInProgress] = useState(false);
  const [cleared, setCleared] = useState(true);

  useEffect(() => {
    setGrid(initialNodes());
  }, []);

  const handleMouseDown = (row, col) => {
    if (inProgress) {
      return;
    }
    if (!grid[row][col].isStart && !grid[row][col].isFinish) {
      const newGrid = getNewGridWithWallToggled(grid, row, col);
      setGrid(newGrid);
      setMouseIsPressed(true);
    }
    if (grid[row][col].isStart || grid[row][col].isFinish) {
      if (grid[row][col].isStart) {
        setMouseIsPressed(true);
        setMoveStartNode(true);
        setPrevNode(grid[row][col]);
      }
      if (grid[row][col].isFinish) {
        setMouseIsPressed(true);
        setMoveEndNode(true);
        setPrevNode(grid[row][col]);
      }
    }
  };

  const handleMouseEnter = (row, col) => {
    if (!mouseIsPressed) return;
    if (moveStartNode) {
      if (prevNode.isStart !== undefined) {
        const newGrid = moveNode(
          grid,
          prevNode.row,
          prevNode.col,
          false,
          false
        );
        setGrid(newGrid);
      }
      START_NODE_ROW = row;
      START_NODE_COL = col;
      setPrevNode(grid[row][col]);
      const newGrid = moveNode(grid, row, col, true, false);
      setGrid(newGrid);
    }
    if (moveEndNode) {
      if (prevNode.isFinish !== undefined) {
        const newGrid = moveNode(
          grid,
          prevNode.row,
          prevNode.col,
          false,
          false
        );
        setGrid(newGrid);
      }
      FINISH_NODE_ROW = row;
      FINISH_NODE_COL = col;
      setPrevNode(grid[row][col]);
      const newGrid = moveNode(grid, row, col, false, true);
      setGrid(newGrid);
    }
    if (!grid[row][col].isStart && !grid[row][col].isFinish) {
      const newgrid = getNewGridWithWallToggled(grid, row, col);
      setGrid(newgrid);
    }
  };

  const handleMouseUp = () => {
    setMouseIsPressed(false);
    setMoveStartNode(false);
    setMoveEndNode(false);
    setPrevNode({});
  };

  const animateDijkstra = (visitedNodesInOrder, nodesInShortestPathOrder) => {
    for (let i = 0; i <= visitedNodesInOrder.length; i++) {
      if (i === visitedNodesInOrder.length) {
        setTimeout(() => {
          animateShortestPath(nodesInShortestPathOrder);
        }, 10 * i);
        return;
      }
      setTimeout(() => {
        const node = visitedNodesInOrder[i];
        document.getElementById(`node-${node.row}-${node.col}`).className =
          "node node-visited";
      }, 10 * i);
    }
  };

  const animateShortestPath = (nodesInShortestPathOrder) => {
    for (let i = 0; i < nodesInShortestPathOrder.length; i++) {
      setTimeout(() => {
        const node = nodesInShortestPathOrder[i];
        document.getElementById(`node-${node.row}-${node.col}`).className =
          "node node-shortest-path";
        if (i === nodesInShortestPathOrder.length - 1) {
          setInProgress(false);
        }
      }, 50 * i);
    }
  };

  const visualizeDijkstra = () => {
    const startNode = grid[START_NODE_ROW][START_NODE_COL];
    const finishNode = grid[FINISH_NODE_ROW][FINISH_NODE_COL];
    const visitedNodesInOrder = dijkstra(grid, startNode, finishNode);
    const nodesInShortestPathOrder = getNodesInShortestPathOrder(finishNode);
    animateDijkstra(visitedNodesInOrder, nodesInShortestPathOrder);
  };

  const visualize = () => {
    setInProgress(true);
    setCleared(false);
    switch (algo) {
      case "Dijkstra Algorithm":
        visualizeDijkstra();
        break;

      default:
        break;
    }
  };

  const clean = () => {
    setGrid(initialNodes());
    grid.map((row, idx) => {
      row.map((_, nodeIdx) => {
        document.getElementById(`node-${idx}-${nodeIdx}`).className = "node";
        return null;
      });
      return null;
    });
  };

  return (
    <div className="visualizer">
      {grid.map((row, rowIdx) => {
        return (
          <div key={rowIdx} className="row">
            {row.map((node, nodeIdx) => {
              const { col, row, isStart, isFinish, isWall } = node;
              return (
                <Nodes
                  key={nodeIdx}
                  col={col}
                  row={row}
                  isStart={isStart}
                  isFinish={isFinish}
                  isWall={isWall}
                  mouseIsPressed={mouseIsPressed}
                  onMouseDown={(row, col) => handleMouseDown(row, col)}
                  onMouseEnter={(row, col) => handleMouseEnter(row, col)}
                  onMouseUp={() => handleMouseUp()}
                />
              );
            })}
          </div>
        );
      })}
      <div className="controlContainer">
        <p className="title">Path Finding Visualizer</p>
        <div className="divider" />
        <ClickAwayListener onClickAway={() => setDropDown(false)}>
          <div
            className={`${dropDown && "activeBackground"} algorithms`}
            onClick={() => setDropDown(!dropDown)}
          >
            {algo !== "" ? `${algo} !` : `Select an Algorithm`}
            <div
              className={`${
                dropDown ? "dropDownActive" : "dropDownInActive"
              } dropdown`}
            >
              {algorithms.map((ele, index) => {
                return (
                  <p key={index} onClick={() => setAlgo(ele)}>
                    {ele}
                  </p>
                );
              })}
            </div>
          </div>
        </ClickAwayListener>
        <div className="divider" />
        <button
          className="btn"
          onClick={() => {
            if (cleared !== true) {
              alert("Please Clear the Board");
            } else if (algo === "") {
              alert("Please Select an Algorithm to Visualize!");
            } else {
              visualize();
            }
          }}
          style={{ backgroundColor: inProgress ? "red" : "#4ebd9c" }}
          disabled={inProgress}
        >
          Visualize !
        </button>
        <div className="divider" />
        <button
          className="clean"
          onClick={() => {
            setCleared(true);
            clean();
          }}
          disabled={inProgress}
        >
          Clean Board
        </button>
      </div>
      <div className="infoContainer">
        <div className="row">
          <img
            src="https://nanthakumaran-s.github.io/pathfinding-visualizer/startIndicator.svg"
            alt="start"
            className="start"
          />
          <p className="infoText">Start Node</p>
        </div>
        <div className="row">
          <img
            src="https://nanthakumaran-s.github.io/pathfinding-visualizer/endIndicator.svg"
            alt="end"
            className="end"
          />
          <p className="infoText">End Node</p>
        </div>
        <div className="row">
          <div className="wall" />
          <p className="infoText">Wall</p>
        </div>
        <div className="row">
          <div className="emptyNode" />
          <p className="infoText">Un Visited Node</p>
        </div>
        <div className="row">
          <div className="visitedNode" />
          <p className="infoText">Visited Node</p>
        </div>
        <div className="row">
          <div className="path" />
          <p className="infoText">Path</p>
        </div>
      </div>
    </div>
  );
};

export default PathFindingVisualizer;

const initialNodes = () => {
  const tempGrid = [];
  for (let row = 0; row < 20; row++) {
    const currentRow = [];
    for (let col = 0; col < 50; col++) {
      currentRow.push(createNode(col, row));
    }
    tempGrid.push(currentRow);
  }
  return tempGrid;
};

const createNode = (col, row) => {
  return {
    col,
    row,
    isStart: row === START_NODE_ROW && col === START_NODE_COL,
    isFinish: row === FINISH_NODE_ROW && col === FINISH_NODE_COL,
    distance: Infinity,
    isVisited: false,
    isWall: false,
    previousNode: null,
  };
};

const getNewGridWithWallToggled = (grid, row, col) => {
  const newGrid = grid.slice();
  const node = newGrid[row][col];
  const newNode = {
    ...node,
    isWall: !node.isWall,
  };
  newGrid[row][col] = newNode;
  return newGrid;
};

const moveNode = (grid, row, col, start, end) => {
  const newGrid = grid.slice();
  const node = newGrid[row][col];
  const newNode = {
    ...node,
    isWall: false,
    isStart: start,
    isFinish: end,
  };
  newGrid[row][col] = newNode;
  return newGrid;
};
